import React from 'react'
/** @jsx jsx */
import { jsx, css, keyframes } from '@emotion/core'
import styled  from '@emotion/styled'
import { Card, CardActions, CardMedia, CardContent, Typography } from '@material-ui/core';
import { flexRowCenterAll } from '../common/format-styles';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { animated } from 'react-spring'
import { navigate } from 'gatsby';
import { Product } from '../data/product-model';
import { toCurrency } from '../helper/to-currency';
import { theme } from '../common/site-variables';
import { cutDescriptionShort } from '../helper/cutDescription';
import { Link } from 'gatsby';


import {
    breakpoints,
    colors,
    fonts,
    radius,
    spacing,
    animations
  } from '../utils/styles';
import { getFileURL } from '../services/product-service';

interface PropTypes {
    isMobile?: boolean;
    className?: string;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
    product: Product;
    active: boolean;
    detProps: any;
    height?: string;
    descCharCount: number;
}

const DESCRIPTION_LIMIT = 90;
const NAME_LIMIT = 40;
const TRANSITION_DURATION = '250ms';

const ProductListingItemLink = styled(Link)`
  background: ${colors.lightest};
  border-radius: ${radius.large}px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15);
  margin-bottom: ${spacing.lg}px;
  overflow: hidden;
  text-decoration: none;
  transition: all ${TRANSITION_DURATION};

  @media (min-width: ${breakpoints.tablet}px) {
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;
  }

  @media (min-width: ${breakpoints.desktop}px) {
    flex-basis: 300px;
    justify-content: center;
    margin: ${spacing.md * 1.25}px;
  }

  @media (hover: hover) {
    :hover {
      background: ${colors.brandLighter};
    }
  }
`;

const Item = styled(`article`)`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: ${spacing.lg}px;
`;


const Name = styled(`h1`)`
  color: ${colors.brandDark};
  font-family: ${fonts.heading};
  font-size: 1.2rem;
  line-height: 1.2;
  margin: 0;
  margin-bottom: 5%;
`;

const Description = styled(`p`)`
  color: ${colors.text};
  flex-grow: 1;
  font-size: 1rem;
  line-height: 1.5;
`;

const PriceRow = styled(`div`)`
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  margin-top: ${spacing.xs}px;
`;

const Price = styled(`div`)`
  color: ${colors.brand};
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: -0.02em;

  span {
    color: ${colors.textLight};
  }
`;

const Incentive = styled('div')`
  align-items: center;
  color: ${colors.lilac};
  display: flex;
  font-size: 0.9rem;
  line-height: 1.3;
  margin-bottom: ${spacing['2xs']}px;
  margin-right: calc(-${spacing.lg}px - 40px);
  text-align: right;
  transition: all ${TRANSITION_DURATION};

  @media (hover: hover) {
    ${ProductListingItemLink}:hover & {
      transform: translateX(-40px);
    }
  }

  > span {
    svg {
      display: inline;
      margin-right: -${spacing['3xs']}px;
      vertical-align: middle;
    }
  }
`;

const CartIcon = styled(`span`)`
  align-items: center;
  background: ${theme.cs_yellow};
  border-radius: 4px 0 0 4px;
  display: flex;
  height: 40px;
  justify-content: center;
  margin-left: 5px;
  position: relative;
  transition: all ${TRANSITION_DURATION};
  vertical-align: middle;
  width: 40px;

  @media (hover: hover) {
    ${ProductListingItemLink}:hover & {
      margin-left: ${spacing.xs}px;
    }
  }

  svg {
    color: ${theme.cs_blue};
    height: 22px;
    position: relative;
    width: 22px;
  }
`;

const Preview = styled(`div`)`
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  border-bottom: 1px solid ${colors.brandLight};
  border-radius: ${radius.large}px ${radius.large}px 0 0;
  margin: 0 auto;
  margin-bottom: ${spacing.lg}px;
  overflow: hidden;
  position: relative;

  img {
    transition: all ${TRANSITION_DURATION};
    transform-origin: bottom right;
  }

  @media (hover: hover) {
    ${ProductListingItemLink}:hover & {
      img {
        transform: scale(2);
      }
    }
  }
`;

const ProdImage = styled(`img`)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 90%;
`;

const apiUrl = process.env.DIRECTUS_API_URL;

const ProductCard = (props: PropTypes) => {
    const { isMobile, className, onMouseEnter, onMouseLeave, product, active, detProps, height, descCharCount } = props;

    const zoom = keyframes(`
        from {transform: scale(1)}
        to {transform: scale(1.1)}
    `);


    const handleCardClick = (id: string) => () => {
        navigate(`product/?id=${id}`);
    }

    const handleCardMouseEnter = () => {
        onMouseEnter();
    }

    const handleCardMouseLeave = () => {
        onMouseLeave();
    }

    const createMarkup = (data: string) => {
      return {__html: data};
    }


    return (
      <ProductListingItemLink to={`/product/?id=${product.id}`} aria-label={product.title}>
        <Item>

          {product.images[0] ?
          <Preview>
            <ProdImage src={getFileURL(product.images[0].directus_files_id)} />
          </Preview>
          : 
          'No preview image'
          }

          <Name>
            {cutDescriptionShort(
              product.title,
              NAME_LIMIT
            )}
          </Name>

          <Description >
            {cutDescriptionShort(
              product.short_description,
              DESCRIPTION_LIMIT
            )}
          </Description>

          <PriceRow>
            <Price>
              <span>USD</span> {toCurrency(product.price)}
            </Price>
            <Incentive>
              <span>
                view details
                <br />& buy <ArrowForwardIcon />
              </span>
              <CartIcon to="">
                <ShoppingCartIcon />
              </CartIcon>
            </Incentive>
          </PriceRow>
          
        </Item>

        </ProductListingItemLink>

    )
}

export default ProductCard